@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

@tailwind base;
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 14px;
  border: 3px solid var(--primary);
}
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-primary-text;
    @apply bg-background;
  }

  pre {
    font-family: inherit;
  }

  .dark body {
    @apply text-primary-text-dark;
    @apply bg-background-dark;
  }

  .dark input[type="date"],
  .dark input[type="time"] {
    color-scheme: dark;
  }
}

@layer components {
  .dark .react-select__control,
  .dark .react-select__menu {
    @apply !bg-background-primary-dark;
  }

  .dark .react-select__option,
  .dark .react-select__multi-value {
    @apply !bg-background-secondary-dark;
  }

  .dark .react-select__option:active {
    @apply !bg-background-secondary-dark/70;
  }

  .dark .react-select__multi-value__label {
    @apply !text-primary-text-dark/80;
  }

  .dark .react-flow__edge-textbg {
    @apply !fill-gray-600/90;
  }

  .dark .react-flow__edge-text {
    @apply !fill-white;
  }
}

@layer components {
  .peer-checked + .radio-indicator {
    @apply bg-blue-400 border-transparent ring-2 ring-blue-400;
  }
}

.loader {
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

label {
  cursor: inherit;
}

svg {
  pointer-events: none;
}

.edgebutton {
  width: 20px;
  height: 20px;
  background: #eee;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.edgebutton-foreignobject body {
  background: transparent;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
}

.react-flow__handle {
  height: 14px !important;
  width: 14px !important;
}

.react-flow__handle-bottom {
  z-index: 10;
  bottom: -8px !important;
}

.react-flow__handle-top {
  z-index: 10;
  top: -8px !important;
}

.react-flow__node-input {
  background: unset !important;
  border: unset !important;
  border-radius: unset !important;
  color: unset !important;
  font-size: unset !important;
  padding: unset !important;
  text-align: unset !important;
  width: unset !important;
}

.react-flow__controls,
.react-flow__minimap {
  padding: inherit;
  margin: 0 !important;
}

.react-flow__attribution {
  display: none !important;
}

.select__menu {
  z-index: 999 !important;
  position: unset !important;
}

.react-flow__node {
  z-index: 2 !important;
}

.react-flow__node-loop {
  z-index: 0 !important;
}

.react-flow__edges {
  z-index: 1 !important;
}

.mobile-navigation_button {
  @apply cursor-default rounded-full bg-transparent hover:!bg-transparent dark:hover:!bg-transparent !p-4 disabled:!border-gray-500 dark:!bg-transparent dark:disabled:!border-gray-500 dark:disabled:!bg-transparent;
}

.tox-tinymce {
  border-radius: unset !important;
}

.dot {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #4b9cdb;
}

.container .dot:nth-last-child(1) {
  animation: jumpingAnimation 0.6s 0.1s ease-in infinite;
}
.container .dot:nth-last-child(2) {
  animation: jumpingAnimation 0.6s 0.2s ease-in infinite;
}
.container .dot:nth-last-child(3) {
  animation: jumpingAnimation 0.6s 0.3s ease-in infinite;
}

@keyframes jumpingAnimation {
  0 {
    transform: translate3d(0, 0,0);
  }
  50% {
    transform: translate3d(0, 15px,0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

iframe#webpack-dev-server-client-overlay{display:none!important}

.scene {
  position: relative;
  z-index: 2;
  height: 220px;
  width: 220px;
  display: grid;
  place-items: center;
}

.cube-wrapper {
  transform-style: preserve-3d;
  animation: bouncing 2s infinite;
}

.cube {
  transform-style: preserve-3d;
  transform: rotateX(45deg) rotateZ(45deg);
  animation: rotation 2s infinite;
}

.cube-faces {
  transform-style: preserve-3d;
  height: 80px;
  width: 80px;
  position: relative;
  transform-origin: 0 0;
  transform: translateX(0) translateY(0) translateZ(-40px);
}

.cube-face {
  position: absolute;
  inset: 0;
  background: #3b5aa9;
  border: solid 1px rgb(255, 255, 255);
}
.cube-face.shadow {
  transform: translateZ(-80px);
  animation: bouncing-shadow 2s infinite;
}
.cube-face.top {
  transform: translateZ(80px);
}
.cube-face.front {
  transform-origin: 0 50%;
  transform: rotateY(-90deg);
}
.cube-face.back {
  transform-origin: 0 50%;
  transform: rotateY(-90deg) translateZ(-80px);
}
.cube-face.right {
  transform-origin: 50% 0;
  transform: rotateX(-90deg) translateY(-80px);
}
.cube-face.left {
  transform-origin: 50% 0;
  transform: rotateX(-90deg) translateY(-80px) translateZ(80px);
}

@keyframes rotation {
  0% {
    transform: rotateX(45deg) rotateY(0) rotateZ(45deg);
    animation-timing-function: cubic-bezier(0.17, 0.84, 0.44, 1);
  }
  50% {
    transform: rotateX(45deg) rotateY(0) rotateZ(225deg);
    animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
  }
  100% {
    transform: rotateX(45deg) rotateY(0) rotateZ(405deg);
    animation-timing-function: cubic-bezier(0.17, 0.84, 0.44, 1);
  }
}
@keyframes bouncing {
  0% {
    transform: translateY(-40px);
    animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
  }
  45% {
    transform: translateY(40px);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  100% {
    transform: translateY(-40px);
    animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
  }
}
@keyframes bouncing-shadow {
  0% {
    transform: translateZ(-80px) scale(1.3);
    animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
    opacity: 0.05;
  }
  45% {
    transform: translateZ(0);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    opacity: 0.3;
  }
  100% {
    transform: translateZ(-80px) scale(1.3);
    animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
    opacity: 0.05;
  }
}

.report-image {
  max-width: 100%;
  height: auto; /* Maintain aspect ratio */
}

@media (max-width: 600px) {
  .iframe-container {
    padding-bottom: 75%; /* Adjust aspect ratio on smaller screens */
  }
}